.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #f25d27;
}
.sd-spin {
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
}
.popup-btn {
  float: right;
}
.card-divider {
  color: #f25d27 !important;
  border-color: #f25d27 !important;
}
.auto-capitalize-input input {
  text-transform: uppercase;
}

.auto-capitalize-input ::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
.auto-capitalize-input :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
.auto-capitalize-input ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
.auto-capitalize-input :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
.auto-capitalize-input ::placeholder {
  /* Recent browsers */
  text-transform: none;
}
